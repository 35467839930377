let origin = window.ORIGIN;
//租赁场所查询下拉列表
const getSearchListUrl = `${origin}/gateway/hc-space/space/getMsInitInfo`;
//租赁场所列表
const getRentListUrl = `${origin}/gateway/hc-space/space/getMsSpaceList`;
//租赁场所详情
const getRentDetailUrl = `${origin}/gateway/hc-space/space/detail`;
//新增租赁场所
const addRentUrl = `${origin}/gateway/hc-space/space/update`;
//租赁订单
const getRentOrderUrl = `${origin}/gateway/hc-space/space/getMsRentApplyList`;
//租赁提交
const postRentOrderUrl = `${origin}/gateway/hc-external/makerSpace/doApply`;
export {
  getSearchListUrl,
  getRentListUrl,
  getRentDetailUrl,
  addRentUrl,
  getRentOrderUrl,
  postRentOrderUrl,
};
